import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import CountryService from "@/core/services/country.service";
import PaymentsListService from "@/core/services/payments-list.service";

export default {
  props: {},
  name: "payments-list",
  components: {},
  data() {
    return {
      selectedShop: null,
      searchShops: "",
      shops: [],
      shopsLoading: false,
      pagination: {},
      total: 10,
      activeCountryId: null,
      error: null,
      shopTransactions: [],
      transactionsLoading: false,
      dateFromMenu: false,
      dateFrom: null,
      dateToMenu: false,
      dateTo: null,
      componentLoaded: false,
      dialog: false,
      selectedShopTransactions: [],
      selectedShopTransactionsTotal: 0,
      selectShopTransactionsAmount: null,
      shopTransactionsDateMenu: false,
      shopTransactionsDate: null,
      commission: 0,
      selectAll: false
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.componentLoaded) return;
        this.getShopTransactions();
      },
      deep: true
    },
    dialog(val) {
      val || this.closeDialog();
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.loadShopsDropDown();
    this.activeCountryId = CountryService.getActiveCountry();
    await PaymentsListService.init();
    this.componentLoaded = true;
  },
  methods: {
    loadShopsDropDown() {
      if (this.shopsLoading) return;

      this.shopsLoading = true;

      ApiService.query("api/shops/dropdown-list", { countryId: this.activeCountryId })
        .then(res => {
          if (!res?.data) return;
          this.shops = res.data;
        })
        .catch(error => (this.error = error))
        .finally(() => (this.shopsLoading = false));
    },
    getShopTransactions() {
      if (this.transactionsLoading) return;
      if (!this.selectedShop?.id) {
        this.resetTansactionsAndPagination();
        return;
      }

      const params = {
        shopId: this.selectedShop.id,
        page: this.pagination.page,
        pageSize: this.pagination.itemsPerPage,
        timeFrom: this.dateFrom,
        timeTo: this.dateTo
      };

      this.transactionsLoading = true;
      PaymentsListService.getShopTransactions(params)
        .then(res => {
          if (!res.data?.result) return;
          this.shopTransactions = res.data.result.map(item => ({
            selected: false,
            paid: true,
            ...item
          }));
          this.total = res.data.totalCount;
        })
        .catch(error => (this.error = error))
        .finally(() => (this.transactionsLoading = false));
    },
    resetTansactionsAndPagination() {
      this.shopTransactions = [];
      this.total = 0;
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      }).format(new Date(date));
    },
    openDialog() {
      this.dialog = true;
      this.selectedShopTransactions = this.getSelectedShopTransactions();
      this.selectedShopTransactionsTotal = this.selectedShopTransactions.reduce(
        (acc, curr) => acc + curr.finalPrice,
        0
      );
      console.log("selected Shop Transactions", this.selectedShopTransactions);
    },
    closeDialog() {
      this.dialog = false;
      this.selectAll = false;
      this.selectedShopTransactions = [];
      this.shopTransactions.forEach(item => (item.selected = false));
      this.commission = 0;
      this.selectShopTransactionsAmount = null;
    },
    getSelectedShopTransactions() {
      return this.shopTransactions.filter(item => item.selected);
    },
    toggleSelectAll() {
      this.shopTransactions.forEach(item => {
        item.selected = !!this.selectAll;
      });
    },
    recalcShopTransactions() {
      this.selectShopTransactionsAmount =
        this.selectedShopTransactionsTotal -
        this.selectedShopTransactionsTotal * (this.commission / 100);
    },
    onSaveTransactions() {
      console.log("commission", this.commission);
      console.log("shopTransactionsDate", this.shopTransactionsDate);
      console.log("selectedShopTransactionsTotal", this.selectedShopTransactionsTotal);
    }
  },
  computed: {
    selectedCount() {
      return this.shopTransactions.filter(item => item.selected)?.length;
    },
    name() {
      return this.$i18n.t("menu.payments");
    },
    headers() {
      return [
        {
          text: "",
          align: "left",
          value: "selected",
          sortable: false
        },
        {
          text: "Date",
          align: "left",
          value: "createdAt",
          sortable: true
        },
        {
          text: "Order ID",
          align: "left",
          value: "orderNumber",
          sortable: true
        },
        {
          text: "Product Name",
          align: "left",
          value: "itemName",
          sortable: true
        },
        {
          text: "Quantity",
          align: "left",
          value: "quantity",
          sortable: true
        },
        {
          text: "Gross Price",
          align: "left",
          value: "grossPrice",
          sortable: true
        },
        {
          text: "Order Status",
          align: "left",
          value: "orderStatus",
          sortable: true
        },
        {
          text: "Neto Price",
          align: "left",
          value: "netPrice",
          sortable: true
        },
        {
          text: "Paid",
          align: "left",
          value: "paid",
          sortable: false
        },
        {
          text: "VAT",
          align: "left",
          value: "vatAmount",
          sortable: true
        },
        {
          text: "Total",
          align: "left",
          value: "finalPrice",
          sortable: true
        }
      ];
    }
  }
};
