import UrlHandlerApiService from "./url-handler.service";
import Vue from "vue";

const PaymentsListService = {
  URL: "",
  API_TRANSACTIONS: "/membership/api/membership/v1/Wallet/GetShopTransactions",

  async init() {
    this.URL = await UrlHandlerApiService.getBaseUrl();
  },

  async getShopTransactions(params) {
    try {
      return Vue.axios.get(this.URL + this.API_TRANSACTIONS, { params });
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] PaymentsListService ${error}`);
    }
  },

  async calculateAndPayShopTransactions() {
    console.log('calculate And Pay ShopTransactions called!!');
  }
};

export default PaymentsListService;
